ul {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 5px solid #e1e1e1;
  box-shadow: 1px 3px 8px #888;
}

.list-item {
  padding: 10px 15px;
  margin: 4px;
  background: #eee;
}
.list-item:hover {
  background: darken(#eee, 5%);
}

.placeholder {
  background: rgb(255, 240, 120);
}
.placeholder:before {
  content: "Drop here";
  color: rgb(225, 210, 90);
}
